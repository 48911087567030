.card {
    --background-color: white;
    --padding: 0;

    background-color: var(--background-color);
    box-shadow: 0 0 20px rgb(0 0 0 / 10%);
    border-radius: 20px;
    padding: var(--padding);
}
.centered-card {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
}
