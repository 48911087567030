.unfilled-button {
    color: var(--border-color);
    background-color: white;
    font-size: var(--font-size);
    border: solid 2px var(--border-color);
    border-radius: var(--border-radius);
    padding: var(--padding);
    min-height: var(--height);
}
.unfilled-button:hover {
    border-color: var(--hover-color);
    color: var(--hover-color);
}
