.form-error {
    height: 20px;
    position: relative;
    left: 20px;
    top: -10px;
    font-family: Montserrat-SemiBold;
    background-color: white;
    width: fit-content;
    display: flex;
    gap: 5px;
}
.form-error p {
    font-family: Montserrat-SemiBold;
    color: var(--red);
    font-size: 12px;
}
.form-error p:first-letter {
    text-transform: capitalize;
}
.form-label {
    font-size: 14px;
    font-family: Montserrat-Medium;
    font-variant: small-caps;
    gap: 1rem;
    text-transform: lowercase;
    color: rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: row;
    align-items: center;
}
.form-label p {
    font-size: 14px;
    font-family: Montserrat-Medium;
    font-variant: small-caps;
    text-transform: lowercase;
    color: rgba(0, 0, 0, 0.75);
}
.field-container {
    width: 100%;
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 5px;
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0 0 3px rgb(0 0 0 / 3%);
    transition: margin-top 0.3s, background-color 0.3s, opacity 0.3s, border 0.3s, box-shadow 0.3s;
    min-height: 54px;
    transition: all 0.3s ease;
    border: 1px solid transparent;
}
.field-container input {
    background-color: transparent;
}
.field-container:focus-within {
    border-color: #ddd;
    box-shadow: 0 0 5px rgb(0 0 0 / 10%);
}
.field-container input,
.field-container select {
    border: none;
    border-bottom: 2px solid var(--blue-dark-alt);
    outline: 0;
    height: 30px;
    border-radius: 0;
    width: 100%;
}
.field-container input:focus,
.field-container select:focus {
    border-bottom: 2px solid var(--gold);
}
.borderless-fieldset {
    padding: 20px 30px 30px 30px;
    box-shadow: 0 0 20px rgb(0 0 0 / 10%);
    border-radius: 10px;
    gap: 5px;
    display: flex;
    flex-direction: column;
    border: none;
    margin: 1rem 0;
}
.input-container {
    width: 100%;
    position: relative;
    padding-bottom: 3px;
}
.input-container .form-error {
    top: 100%;
    position: absolute;
}
