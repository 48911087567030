.icon-power {
    width: 30px;
    height: 30px;
    cursor: pointer;
    border: 2px solid #646262;
    border-radius: 10px;
    padding: 5px;
}
.icon-power:hover {
    background-color: #cad4f7;
}
