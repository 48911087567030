.toggle-container {
    display: flex;
}
.toggle-input {
    cursor: pointer;
    visibility: hidden;
    width: 0 !important;
}
.toggle-front {
    cursor: pointer;
    width: 35px !important;
    margin: 0 2rem;
}
.toggle-front::before {
    width: 35px;
    margin-top: 5px;
    height: 20px;
    background-color: var(--bg-color);
}
.checked::before {
    background-color: var(--color);
}
.toggle-front::before,
.toggle-front::after {
    visibility: visible;
    content: '\00a0';
    border-radius: 15px;
    display: block;
}
.toggle-front::after {
    margin-top: -18px;
    margin-left: 2px;
    background-color: #fff;
    height: 16px;
    width: 16px;
    transition: margin-left 0.3s;
}
.checked::after {
    margin-left: 17px;
}
.toggle-container label {
    cursor: pointer;
}
