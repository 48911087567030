.notification-background {
    width: 100%;
    position: fixed;
    top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.notification-hidden {
    animation-name: fadeout;
    animation-duration: 0.4s;
    opacity: 0;
    z-index: -1;
}
.notification-shown {
    animation-name: fadein;
    animation-duration: 0.4s;
    opacity: 1;
    z-index: 9999;
}
.notification-container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    border-radius: 15px;
    background-color: var(--background-color);
    padding: 20px;
    transition: opacity 0.3s;
    opacity: 1;
}
.notification-content,
.notification-content * {
    color: var(--color);
}
.notification-content {
    width: 100%;
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

@keyframes fadeout {
    0% {
        opacity: 1;
        z-index: 9999;
    }
    99% {
        z-index: 9999;
    }
    100% {
        opacity: 0;
        z-index: -1;
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
        z-index: -1;
    }
    1% {
        z-index: 9999;
        opacity: 0;
    }
    100% {
        opacity: 1;
        z-index: 9999;
    }
}
