input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.before-euro::before,
.before-percent::before {
    position: absolute;
    font-family: Montserrat-Semibold;
    font-variant: small-caps;
    font-size: 15px;
    top: 3px;
}
.before-euro::before {
    content: '€';
}
.before-percent::before {
    content: '%';
}
.before-euro input,
.before-percent input {
    padding-left: 20px;
}
.before-search input {
    padding-left: 25px;
}
.before-search-content {
    position: absolute;
    top: 3px;
}
.filled-input {
    background-color: var(--bg-color);
    color: white !important;
    border-radius: 15px;
    padding: 15px 20px 15px 20px;
    font-weight: bold;
}
.filled-input .form-error {
    color: white;
    background-color: transparent;
    top: 30px;
}
.filled-input input {
    background-color: var(--bg-color);
    color: white;
}
.filled-input .before-euro::before,
.filled-input .before-percent::before {
    color: white;
}
.filled-input .form-label {
    color: white;
}
.label-tip {
    color: var(--blue-focus);
    font-size: 0.8rem;
}
