.unfilled-button,
.padding-button,
.color-button {
    font-family: Montserrat-SemiBold;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
    width: fit-content;
    gap: 1rem;
}
.invisible-button {
    border: none;
    background-color: transparent;
}
