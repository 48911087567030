.m-0 {
    margin: 0;
}
.mx-lg {
    margin-left: 30px;
    margin-right: 30px;
}
.mx-md {
    margin-left: 20px;
    margin-right: 20px;
}
.mx-sm {
    margin-left: 10px;
    margin-right: 10px;
}
.my-lg {
    margin-top: 30px;
    margin-bottom: 30px;
}
.my-md {
    margin-top: 20px;
    margin-bottom: 20px;
}
.my-sm {
    margin-top: 10px;
    margin-bottom: 10px;
}
.mt-3 {
    margin-top: 1rem;
}
.mb-3 {
    margin-bottom: 1rem;
}
.mt-lg {
    margin-top: 30px;
}
.mb-lg {
    margin-bottom: 30px;
}
.mt-md {
    margin-top: 20px;
}
.mb-md {
    margin-bottom: 20px;
}
.mt-sm {
    margin-top: 10px;
}
.mb-sm {
    margin-bottom: 10px;
}
.m-lg {
    margin: 30px;
}
.m-md {
    margin: 20px;
}
.m-sm {
    margin: 10px;
}

.p-0 {
    padding: 0;
}
.px-lg {
    padding-left: 30px;
    padding-right: 30px;
}
.px-md {
    padding-left: 20px;
    padding-right: 20px;
}
.px-sm {
    padding-left: 10px;
    padding-right: 10px;
}
.py-lg {
    padding-top: 30px;
    padding-bottom: 30px;
}
.py-md {
    padding-top: 20px;
    padding-bottom: 20px;
}
.py-sm {
    padding-top: 10px;
    padding-bottom: 10px;
}
.pt-3 {
    padding-top: 1rem;
}
.pb-3 {
    padding-bottom: 1rem;
}
.pt-sm {
    padding-top: 10px;
}
.pb-sm {
    padding-bottom: 10px;
}
.pt-md {
    padding-top: 20px;
}
.pb-md {
    padding-bottom: 20px;
}
.pt-lg {
    padding-top: 30px;
}
.pb-lg {
    padding-bottom: 30px;
}
.p-lg {
    padding: 30px;
}
.p-md {
    padding: 20px;
}
.p-sm {
    padding: 10px;
}

.gap-lg {
    gap: 30px;
}
.gap-md {
    gap: 20px;
}
.gap-sm {
    gap: 10px;
}
