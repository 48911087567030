.case-input-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
    padding-top: 10px;
}
.case-input-container input {
    width: 10rem;
    padding: 10px;
    padding-right: 25px;
    border: none;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 15px rgb(0 0 0 / 20%);
}
.case-input-container input:focus {
    outline: 2px solid var(--gold);
}
.case-input-container label {
    font-family: Montserrat-SemiBold;
    color: var(--color);
}
.case-input-container .before-euro,
.case-input-container .before-percent {
    position: relative;
}
.case-input-container .before-euro::before,
.case-input-container .before-percent::before {
    content: '€';
    position: absolute;
    display: flex;
    align-items: center;
    right: 10px;
    top: 10px;
    font-family: Montserrat-Bold;
    font-size: 1.1rem;
}
.case-input-container .before-percent::before {
    content: '%';
}
@media (max-width: 500px) {
    .case-input-container {
        flex-direction: column;
        gap: 0.8rem;
    }
    .case-input-container span,
    .case-input-container input {
        width: 100%;
    }
}
