.footer-container {
    margin-bottom: 3rem;
}
.footer-container p {
    font-size: 0.9rem;
}
.footer-container h4 {
    font-size: 24px;
    margin-top: 2rem;
    margin-bottom: 1.5rem;
    font-family: Montserrat-SemiBold;
    color: var(--blue-dark);
}
.footer-container img {
    margin-bottom: 1rem;
}
.kweerty {
    font-size: 17px;
    text-align: center;
    margin: auto;
    font-weight: 600;
    margin: 20px 0;
    color: #919191;
}
.kweerty a {
    color: var(--gold);
    text-decoration: none;
}
.kweerty a:hover {
    color: #0ba8c2 !important;
    opacity: 0.7;
}
@media only screen and (max-width: 1000px) {
    .footer-container {
        display: block;
    }
    .footer-container .col {
        width: 100% !important;
    }
}
