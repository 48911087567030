@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat-Regular'), url('../../assets/fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'Montserrat-Medium';
    src: local('Montserrat-Medium'), url('../../assets/fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
}
@font-face {
    font-family: 'Montserrat-SemiBold';
    src: local('Montserrat-SemiBold'), url('../../assets/fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype');
}
@font-face {
    font-family: 'Montserrat-Bold';
    src: local('Montserrat-Bold'), url('../../assets/fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
}
@font-face {
    font-family: 'Montserrat-MaxiBoldDeFou';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_epG3gnD_vx3rCs.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
