.icon-burger {
    --width: 35px;

    width: --width;
    display: none;
}

@media only screen and (max-width: 1200px) {
    .icon-burger {
        display: block;
        position: absolute;
        height: 40px;
        top: 40px;
        right: 70px;
        cursor: pointer;
    }
}
