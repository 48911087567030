.arrow-icon {
    color: var(--color);
    transform: rotate(var(--angle));
    width: var(--width);
    cursor: pointer;
    transition: transform 0.2s;
}
.arrow-icon:active {
    transform: scale(1.2) rotate(var(--angle));
}
