.first-input {
    width: 100% !important;
    padding: 10px !important;
}
.double-input-container {
    gap: 3rem;
}
.first-input::placeholder {
    font-style: italic;
    color: var(--grey-dark);
    font-family: Montserrat-Medium;
}
@media (max-width: 500px) {
    .double-input-container {
        margin-top: 15px;
        gap: 0.8rem;
    }
}
