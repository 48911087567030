:root {
    --white: #fff;
    --black: #000;
    --blue: #637fde;
    --blue-clear: #ddedff;
    --blue-dark: #252b41;
    --blue-sky: #01008a;
    --blue-focus: #647fdf;
    --blue-dark-alt: #6d6f92;
    --gold: #c8b478;
    --grey: #e5e5e5;
    --purple-clear: #e7eaf5;
    --red: #f56e52;
    --grey-dark: #707070;
}
