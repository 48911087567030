.container {
    margin: auto;
    padding: 15px 10px;
}
.row,
.row-1000 {
    display: flex;
    flex-wrap: nowrap;
}
.row-1000 {
    flex-direction: column;
}
.col {
    width: 100%;
}
.s-between {
    display: flex;
    justify-content: space-between;
}
.w-100 {
    width: 100% !important;
    position: relative;
}
.centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.relative {
    position: relative;
}
.absolute {
    position: absolute;
}
.d-flex {
    display: flex;
}
.h-100 {
    height: 100%;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
    .row-1000 {
        flex-direction: row;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

@media (min-width: 1400px) {
    .container {
        max-width: 1320px;
    }
}
