.plan-financement-section {
    gap: 2rem;
    padding: 1rem 0;
    align-items: stretch;
}
.plan-financement-container .card {
    border-radius: 10px;
}
.plan-financement-container .case-input-container input:not(.first-input) {
    font-family: Montserrat-Bold;
}
.plan-financement-section fieldset {
    height: 100%;
    margin: 0;
    justify-content: space-between;
}
.plan-financement-section h2 {
    margin: 0;
    color: white;
    align-items: baseline;
}
.plan-buttons-container {
    display: flex;
    gap: 10px;
}
.last-input-container {
    width: 100%;
    position: absolute;
    bottom: 0;
}
.plan-financement-container {
    margin: 3rem auto;
}
.plan-financement-div {
    transition: 1.5s max-height !important;
}
.plan-financement {
    height: var(--height);
    overflow-y: hidden;
}
.ressources-card input,
.ressources-card span,
.ressources-card .card-content label {
    color: var(--blue-dark-alt);
}
.active-plan {
    outline: 5px solid var(--blue-focus);
    outline-offset: -5px;
}
.warning-financement {
    color: var(--red);
    gap: 5px;
    align-items: center;
    padding-left: 1rem;
    display: flex;
    font-size: 0.9rem;
    height: 26px;
}
.row-with-button {
    display: flex;
    align-items: center;
    position: relative;
}
.row-with-button .trash-container {
    position: absolute;
    display: flex;
    align-items: center;
    right: calc(10rem + 40px);
}
.checkbox-plan-financement {
    margin: 0 5px 0 20px;
    cursor: pointer;
}
.checkbox-label-plan-financement {
    font-family: Montserrat-SemiBold;
    cursor: pointer;
}
.checkbox-container-plan-financement {
    display: flex;
    right: 15rem;
    top: 0;
    flex-direction: row;
    justify-content: flex-end;
    position: absolute;
    height: 100%;
    align-items: center;
}
.h3-plan-financement {
    text-align: center;
    font-family: Montserrat-SemiBold;
    font-weight: normal;
}
.buttons-container-plan-financement {
    display: flex;
    position: relative;
    gap: 20px;
    padding-top: 5px;
    width: 10rem;
    justify-content: center;
    margin-left: auto;
    padding-bottom: 10px;
    height: 59px;
}
.buttons-container-plan-financement svg {
    background-color: var(--purple-clear);
    border-radius: 5px;
    overflow: visible;
    padding: 3px;
    border: 3px var(--blue-dark) solid;
}
.ressources-card svg {
    border-color: var(--blue-dark-alt);
}
.buttons-container-plan-financement .cross-icon {
    padding: 4px;
}
div .empty-double-input {
    padding-top: 4px;
}
.lowercase {
    font-variant: normal;
    color: white;
    font-weight: normal;
}
.plan-financement-name {
    font-size: 1.1rem;
    text-align: center;
    max-height: 3.3rem;
    overflow: hidden;
    font-family: Montserrat-SemiBold;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.plan-financement-card {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
}
.replace-shadows {
    width: 1300px;
}
.replace-shadows .case-input-container input {
    box-shadow: none;
    border: 2px solid var(--blue-dark);
}
.replace-shadows .card {
    box-shadow: none;
}

@media (max-width: 1400px) {
    .ebe-container {
        flex-direction: column;
    }
    .checkbox-container-plan-financement {
        position: relative;
        justify-content: center;
        right: 0;
        margin-top: 10px;
    }
}
@media (max-width: 500px) {
    .row-with-button .trash-container {
        right: 10px;
        top: 10px;
    }
}
