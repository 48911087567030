* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 16px;
    font-family: Montserrat-Medium;
    color: var(--blue-dark);
    accent-color: var(--blue-focus);
}

::-webkit-scrollbar {
    width: 10px;
    border-radius: 15px;
}
::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 15px;
}
::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 15px var(--blue-focus);
    background-color: var(--blue-focus);
    border-radius: 15px;
}
::selection {
    background: var(--blue-focus);
}
svg,
image {
    vertical-align: middle;
}
a {
    text-decoration: none;
}
p {
    line-height: 1.6;
}
.d-none {
    display: none !important;
}
.h-none {
    max-height: 0 !important;
    padding: 0 !important;
}
.behind {
    z-index: -1 !important;
}
.bold {
    font-family: Montserrat-Bold !important;
    color: inherit;
    font-size: inherit;
}
.scaleY-0 {
    transform: scaleY(0);
}
.scaleY-1 {
    transform: scaleY(1);
}
.transition-3 {
    transition: all 0.3s;
}
.bg-blue-focus {
    background-color: var(--blue-focus) !important;
}
.bg-blue-dark {
    background-color: var(--blue-dark) !important;
}
.bg-gold {
    background-color: var(--gold) !important;
}
.prevent-scrolling {
    height: 100%;
    overflow: hidden;
}
