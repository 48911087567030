.color-button {
    color: white;
    border: none;
    font-size: var(--font-size);
    background-color: var(--background-color);
    border-radius: var(--border-radius);
    min-height: var(--height);
    padding: var(--padding);
}
.color-button:hover {
    background-color: var(--hover-color);
}
