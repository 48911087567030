.loader-container {
    top: 0;
    background-color: white;
    width: 100vw;
    position: fixed;
    height: 100vh;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
}
