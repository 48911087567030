.icon-cart {
    width: 30px;
    height: 30px;
    cursor: pointer;
    border: 2px solid var(--gold);
    border-radius: 10px;
    padding: 5px;
}
.icon-cart:hover {
    background-color: #fcf6e2;
}
