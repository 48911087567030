h1 {
    font-family: Montserrat-Bold;
    color: white;
    font-size: 2.5rem;
}
h2 {
    display: flex;
    align-items: center;
    text-transform: lowercase;
    font-variant: small-caps;
    font-size: 24px;
    font-family: Montserrat-SemiBold;
    margin-bottom: 20px;
}
.link {
    cursor: pointer;
}
.link:hover {
    opacity: 0.7;
}
.blue-text {
    color: var(--blue-focus) !important;
    font-size: inherit;
}
.gold-text {
    color: var(--gold) !important;
}
.blue-dark-text {
    color: var(--blue-dark) !important;
}
.grey-text {
    color: var(--grey-dark) !important;
}
.white-text {
    color: white !important;
}
.red-text {
    color: var(--red) !important;
}
.text-xs {
    font-size: 0.8rem;
}
.text-sm {
    font-size: 0.9rem;
}
.text-centered {
    text-align: center;
}
