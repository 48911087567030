.banner {
    background-color: var(--blue-dark);
    width: 100%;
    padding: 2rem 0 2rem 20%;
    position: relative;
}
.back-button-container {
    position: absolute;
    top: 2rem;
    left: 2rem;
}
.banner-content hr {
    width: 20vw;
    margin: 1rem 0;
    border: none;
    height: 0;
    border-bottom: 3px solid var(--gold);
    opacity: 1 !important;
}
.banner-content p {
    color: white;
}
