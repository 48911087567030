.container {
    padding-top: 20px;
}
.tools {
    display: flex;
    flex-direction: column;
    margin-bottom: 14px;
    gap: 2px;
    align-items: center;
}
.logo-container {
    padding-right: 20px;
    width: 33%;
    display: block;
    cursor: pointer;
}
nav {
    display: flex;
}
.navbar-header,
.navbar-container a,
.tools div {
    font-size: 0.9rem;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
}
.stars-container {
    position: absolute;
    top: -18px;
    left: 10px;
}
.navbar-header:hover,
.navbar-container > a:hover,
.tools div:hover {
    color: var(--blue-focus);
}
.navbar-container {
    display: flex;
    width: 67%;
    justify-content: space-between;
    list-style: none;
    align-items: center;
}
.navbar-content {
    z-index: 2;
    position: absolute;
    background-color: #fff;
    min-width: 160px;
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
    border-radius: 5px;
    overflow: hidden;
    transition: all 0.3s ease;
}
.navbar-content a,
.navbar-content i {
    padding: 1rem 2rem;
    width: 100%;
    text-decoration: none;
    display: block;
    line-height: 1.2rem;
    border-bottom: 1px solid var(--grey);
}
.navbar-content a:last-child,
.navbar-content i:last-child {
    border: none;
}
.navbar-content a:hover,
.navbar-content i:hover {
    color: white;
    background-color: var(--blue-focus);
}
.navbar-buttons > div {
    display: flex;
    justify-content: space-between;
}
.navbar-buttons > div .color-button {
    margin: 0 8px;
}
.navbar-buttons div div {
    width: 100%;
}
.burger {
    display: none;
}
.cart-container {
    height: 30px;
    margin: 0 !important;
    padding: 0 !important;
}
.navbar-container > a {
    display: block;
}

@media only screen and (max-width: 1200px) {
    .burger {
        display: block;
        width: 35px;
        position: absolute;
        height: 40px;
        top: 30px;
        right: 70px;
        cursor: pointer;
    }
    nav {
        display: block;
    }
    .navbar-container li,
    .navbar-container a,
    .tools div {
        width: fit-content;
        padding-bottom: 20px;
    }
    .navbar-content a,
    .navbar-content i {
        width: 100%;
    }
    .tools div {
        padding-bottom: 0;
    }
    .navbar-container {
        display: block;
    }
    .show {
        display: none;
    }
    .navbar-container a:first-child,
    .navbar-container i:first-child {
        margin-top: 15px;
        margin-bottom: 0;
    }
    .tools {
        align-items: flex-start;
    }
    .navbar-buttons a {
        padding: 0.375rem 0.75rem;
    }
}
