.padding-button {
    color: white;
    font-size: var(--font-size);
    background-color: var(--background-color);
    border-radius: var(--border-radius);
    padding: var(--padding);
    min-height: var(--height);
    border: none;
}
.padding-button:hover {
    padding: 0.375rem 2rem;
}
